import axios from '@/api/index'
// 品牌区ヾ(*´▽‘*)ﾉ
// 添加品牌
export const Addbrand = data => {
  return axios.request({
    url: '/auto/brand/add',
    method: 'post',
    data
  })
}
// 获取列表
export const GetBraandsList = data => {
  return axios.request({
    url: '/auto/brands',
    method: 'post',
    data
  })
}
// 修改品牌
export const Editbrand = data => {
  return axios.request({
    url: '/auto/brand/edit',
    method: 'post',
    data
  })
}
// 查看一条
export const GetOnebrand = params => {
  return axios.request({
    url: '/auto/brand/show',
    method: 'get',
    params
  })
}
// 删除品牌
export const Delbrand = params => {
  return axios.request({
    url: '/auto/brand/del',
    method: 'get',
    params
  })
}
// 供应商ヾ(*´▽‘*)ﾉSupplier
export const AddSupplier = data => {
  return axios.request({
    url: '/parts_supplier/add',
    method: 'post',
    data
  })
}
// 获取列表
export const GetSupplierList = params => {
  return axios.request({
    url: '/parts_supplier/list',
    method: 'get',
    params
  })
}
// 修改品牌
export const EditSupplier = data => {
  return axios.request({
    url: '/parts_supplier/edit',
    method: 'post',
    data
  })
}
// 查看一条
export const GetOneSupplier = params => {
  return axios.request({
    url: '/parts_supplier/show',
    method: 'get',
    params
  })
}
// 删除品牌
export const DelSupplier = params => {
  return axios.request({
    url: '/parts_supplier/del',
    method: 'get',
    params
  })
}
// 设备型号ヾ(*´▽‘*)ﾉ Equipment
export const AddEquipment = data => {
  return axios.request({
    url: '/equipment/add',
    method: 'post',
    data
  })
}
// 获取列表
export const GetEquipmentList = params => {
  return axios.request({
    url: '/equipment/list',
    method: 'get',
    params
  })
}
// 修改品牌
export const EditEquipment = data => {
  return axios.request({
    url: '/equipment/edit',
    method: 'post',
    data
  })
}
// 查看一条
export const GetOneEquipment = params => {
  return axios.request({
    url: '/equipment/show',
    method: 'get',
    params
  })
}
// 删除品牌
export const DelEquipment = params => {
  return axios.request({
    url: '/equipment/del',
    method: 'get',
    params
  })
}
// 图纸ヾ(*´▽‘*)ﾉ Drawing
export const AddDrawing = data => {
  return axios.request({
    url: '/drawing/add',
    method: 'post',
    data
  })
}
// 获取列表
export const GetDrawingList = params => {
  return axios.request({
    url: '/drawing/list',
    method: 'get',
    params
  })
}
// 修改品牌
export const EditDrawing = data => {
  return axios.request({
    url: '/drawing/edit',
    method: 'post',
    data
  })
}
// 查看一条
export const GetOneDrawing = params => {
  return axios.request({
    url: '/drawing/show',
    method: 'get',
    params
  })
}
// 删除品牌
export const DelDrawing = params => {
  return axios.request({
    url: '/drawing/del',
    method: 'get',
    params
  })
}
// 产品种类ヾ(*´▽‘*)ﾉ Partscategory
export const AddPartscategory = data => {
  return axios.request({
    url: '/partscategory/add',
    method: 'post',
    data
  })
}
// 获取列表
export const GetPartscategoryList = params => {
  return axios.request({
    url: '/partscategory/list',
    method: 'get',
    params
  })
}
// 修改品牌
export const EditPartscategory = data => {
  return axios.request({
    url: '/partscategory/edit',
    method: 'post',
    data
  })
}
// 查看一条
export const GetOnePartscategory = params => {
  return axios.request({
    url: '/partscategory/show',
    method: 'get',
    params
  })
}
// 删除品牌
export const DelPartscategory = params => {
  return axios.request({
    url: '/partscategory/del',
    method: 'get',
    params
  })
}
// 配件ヾ(*´▽‘*)ﾉ Parts
export const AddParts = data => {
  return axios.request({
    url: '/parts/add',
    method: 'post',
    data
  })
}
// 获取列表
export const GetPartsList = params => {
  return axios.request({
    url: '/parts/list',
    method: 'get',
    params
  })
}
// 修改品牌
export const EditParts = data => {
  return axios.request({
    url: '/parts/edit',
    method: 'post',
    data
  })
}
// 查看一条
export const GetOneParts = params => {
  return axios.request({
    url: '/parts/show',
    method: 'get',
    params
  })
}
// 删除品牌
export const DelParts = params => {
  return axios.request({
    url: '/parts/del',
    method: 'get',
    params
  })
}
// 批次ヾ(*´▽‘*)ﾉ Dbatch
export const AddBatch = data => {
  return axios.request({
    url: '/batch/add',
    method: 'post',
    data
  })
}
// 获取列表
export const GetBatchList = params => {
  return axios.request({
    url: '/batch/list',
    method: 'get',
    params
  })
}
// 修改品牌
export const EditBatch = data => {
  return axios.request({
    url: '/batch/edit',
    method: 'post',
    data
  })
}
// 查看一条
export const GetOneBatch = params => {
  return axios.request({
    url: '/batch/show',
    method: 'get',
    params
  })
}
// 删除品牌
export const DelBatch = params => {
  return axios.request({
    url: '/batch/del',
    method: 'get',
    params
  })
}
// 上传文件
export const UpParts = data => {
  return axios.request({
    url: '/parts/excelimport',
    method: 'post',
    data
  })
}
